<!--
 * @Date: 2024-09-14 10:23:25
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-09-14 17:04:16
 * @FilePath: \zyt-mobile-frontend\src\views\me\screenPlanConfirm\modules\cancel.vue
-->
<template>
  <div>
     <!-- 取消计划弹出框-->
     <van-popup
     v-model="showCancel"
     closeable
     position="bottom"
     :style="{ height: '30%' }"
   >
     <div class="cancelPlan">
       <h4><span class="star">*</span>取消原因</h4>
     </div>
     <div>
       <van-field
         v-model="cancelMessage"
         rows="2"
         autosize
         type="textarea"
         maxlength="150"
         placeholder="请描述原因"
         show-word-limit
       />
     </div>
     <div class="cancelPlan">
       <van-button
         class="btn"
         type="danger"
         size="small"
         round
         block
         @click="confirmCancelPlan"
       >
         确认
       </van-button>
     </div>
   </van-popup>
  </div>
</template>
<script>
import { Toast, Dialog } from 'vant'
import {api} from '@/api'
export default {
  data() {
    return {
      showCancel: false,
      cancelMessage: "",
    };
  },
  methods: {
    show(plan){
      this.showCancel = true;
      this.cancelMessage = ""
      this.tempPlanData = plan
    },
     // 确认取消计划
     confirmCancelPlan() {
      this.tempPlanData.filmteamRemark = this.cancelMessage
      if (this.cancelMessage.length < 5) {
        Toast('取消原因不能少于5个字')
        return
      }
      Dialog.confirm({
        title: '取消计划',
        message: '确定取消该计划吗？',
      }).then(() => {
        // on confirm
        this.showCancel = false
        console.log('取消计划')
        api.filmmaker.cancelPlan({planId: this.tempPlanData.id}).then((res) => {
          const {success, message} = res
          if (!success) {
            Toast(message)
            return  
          }
          else{
            Toast('取消成功')
            this.showModify = false
            this.$emit('success')
          }
        })
      })

      console.log(this.tempPlanData)
    },
  },
}
  </script>